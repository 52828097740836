export const ACCESS_KEY = "AKIA4UDXBX5QIWMC4YD5";
export const SECRET_ACCESS_KEY = "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";
export const MEAL_TYPE = {
  FOOD_STACK: "food_stack",
  MEAL_PACK: "meal_pack",
  PRE_ORDER: "pre_order",
  MEAL_KIT: "meal_kit",
  HARVEST_PACK: "harvest_pack",
};

export const JekaEat_FSC2024 = "JekaEat_FSC2024";
export const ReadyToCook24 = "ReadyToCook24";
export const Tech_Week = "Tech week";
export const Sauced_Expo = "Sauced_Expo";
export const fbAdsAddAppiD = "3397200843905556";
export const Jekaeat_50 = "Jekaeat_50%";
export const Jekaeat_free = "jekaeat_free";
export const SERVICE_CHARGE = 50;
export const COMMISSION_CHARGE = 0.02;
export const isProd = process.env.NODE_ENV === "production";
export const isLocal = process.env.NODE_ENV === "development";
export const VendorCategory = {
  Restaurant: "restaurant",
  FreshFood: "fresh_food",
};
