import { Error } from "assets/icon";

import { toast } from "react-toastify";
import { VendorCategory } from "./constant.util";
// import { AxiosError } from "axios";

export function generateRandomID(length: number) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getFromSessionStorage(key: string): string | null {
  if (typeof sessionStorage !== "undefined") {
    return sessionStorage.getItem(key);
  }
  return null;
}
export function errorLogger(error: any) {
  if (error.response?.status === 401) {
    toast.error("Token  expired, login required", {
      icon: Error,
    });
    window.open("/auth/signin");
    return;
  }
  if (
    typeof error?.response?.data?.error === "object" &&
    !Array.isArray(error?.response?.data?.error)
  ) {
    toast.error(error?.response?.data?.message, { icon: Error });
  }
  // Check if the response is an array
  else if (Array.isArray(error?.response?.data?.error)) {
    (error?.response?.data?.error ?? []).map((e: string) =>
      toast.error(e ?? "An error occurred, Please try again", { icon: Error })
    );
  }
}

export function isShopAvailable(schedule: any[]) {
  const currentDay = new Date().toLocaleDateString("en-US", {
    weekday: "long",
  });
  const currentTime = new Date().toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  if (!schedule) {
    return false;
  }
  // Find the schedule entry for the current day
  const currentSchedule = schedule.find(
    (entry) => entry.day.toLowerCase() === currentDay.toLowerCase()
  );

  // If there is no schedule entry for the current day, the restaurant is closed
  if (!currentSchedule) {
    return false;
  }

  // Compare the current time with the opening and closing times
  const { from, to } = currentSchedule;

  return currentTime >= from && currentTime <= to;
}
export function isWebview() {
  const navigator = window.navigator;
  const userAgent = navigator.userAgent;
  const normalizedUserAgent = userAgent.toLowerCase();
  const standalone = navigator.standalone; /// !

  const isIos =
    /ip(ad|hone|od)/.test(normalizedUserAgent) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
  const isAndroid = /android/.test(normalizedUserAgent);
  const isSafari = /safari/.test(normalizedUserAgent);
  const isWebview =
    (isAndroid && /; wv\)/.test(normalizedUserAgent)) ||
    (isIos && !standalone && !isSafari);

  return isWebview;
}

// Function to verify CAC Number input
export function verifyCAC(input: string) {
  // Regular expressions for matching CAC Number formats
  var rcRegex = /^RC\d{6}$/;
  var bnRegex = /^BN\d{6}$/;
  var cacRegex = /^CAC\d{6}$/;

  // Remove any whitespace from the input
  var cleanedInput = input.replace(/\s/g, "");

  // Check if input matches any of the CAC Number formats
  if (rcRegex.test(cleanedInput)) {
    return true;
  } else if (bnRegex.test(cleanedInput)) {
    return true;
  } else if (cacRegex.test(cleanedInput)) {
    return true;
  } else {
    toast.error("Invalid CAC Number format", { icon: Error });
    return false;
  }
}

export const digitFormatter = (num: number) => {
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  return Number(num).toLocaleString("en", options);
};

export const getNairaEquivalent = (num: number | string | any): number => {
  if (num) {
    return Number(num) / 100;
  }
  return 0;
};
export const getKoboEquivalent = (num: number | string | any): number => {
  if (num) {
    return Number(num) * 100;
  }
  return 0;
};

export function convertCurrencyStringToNumber(currencyString: string) {
  // Remove the currency symbol and replace commas with nothing
  const cleanedString = currencyString.replace("₦", "").replace(/,/g, "");
  // Convert the cleaned string to a number
  const number = parseFloat(cleanedString);
  return number;
}

export function applyCommission(initialPrice: number, vendorCategory: string) {
  if (vendorCategory === VendorCategory.Restaurant) {
    if (initialPrice <= 1500) {
      initialPrice = 0.02 * initialPrice + initialPrice;
    } else {
      initialPrice = 1500 * 0.02 + initialPrice + 10;
    }
  } else {
    if (initialPrice <= 5000) {
      initialPrice = initialPrice * 0.04 + initialPrice;
    } else {
      initialPrice = 5000 * 0.04 + initialPrice + 100;
    }
  }
  return initialPrice;
}

export function removeCommission(
  initialPrice: number,
  vendorCategory?: string
) {
  if (vendorCategory === VendorCategory.Restaurant) {
    if (initialPrice <= 1500) {
      initialPrice = initialPrice / 1.02;
    } else {
      initialPrice = initialPrice - 40;
    }
  } else {
    if (initialPrice <= 5000) {
      initialPrice /= 1.04;
    } else {
      initialPrice = initialPrice - 300;
    }
  }
  return initialPrice;
}
