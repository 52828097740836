const priceMapping = {
  "zone1-zone2": 550,
  "zone1-zone3": 700,
  "zone1-zone4": 850,
  "zone1-zone5": 950,
  "zone1-zone6": 1050,
  "zone1-zone7": 1150,
  "zone1-zone8": 1400,
  "zone1-zone9": 1500,
  "zone1-zone10": 1700,
  "zone2-zone3": 450,
  "zone2-zone4": 500,
  "zone2-zone5": 700,
  "zone2-zone6": 700,
  "zone2-zone7": 900,
  "zone2-zone8": 900,
  "zone2-zone9": 900,
  "zone2-zone10": 900,
  "zone3-zone4": 450,
  "zone3-zone5": 600,
  "zone3-zone6": 600,
  "zone3-zone7": 700,
  "zone3-zone8": 700,
  "zone3-zone9": 800,
  "zone3-zone10": 800,
  "zone4-zone5": 600,
  "zone4-zone6": 600,
  "zone4-zone7": 700,
  "zone4-zone8": 800,
  "zone4-zone9": 800,
  "zone4-zone10": 800,
  "zone5-zone6": 500,
  "zone5-zone7": 550,
  "zone5-zone8": 600,
  "zone5-zone9": 700,
  "zone5-zone10": 700,
  "zone6-zone7": 600,
  "zone6-zone8": 550,
  "zone6-zone9": 600,
  "zone6-zone10": 800,
  "zone7-zone8": 600,
  "zone7-zone9": 600,
  "zone7-zone10": 700,
  "zone8-zone9": 550,
  "zone8-zone10": 700,
  "zone9-zone10": 600,
};

export const landmarks = [
  {
    location: "Gida Painting",
    zone: "zone1",
  },
  {
    location: "Talba Road",
    zone: "zone1",
  },
  {
    location: "Gida Kwanu",
    zone: "zone1",
  },
  {
    location: "FUT Hostel",
    zone: "zone2",
  },
  {
    location: "Dama",
    zone: "zone2",
  },
  {
    location: "Gidan Doki",
    zone: "zone3",
  },

  {
    location: "Gida Mangoro ",
    zone: "zone3",
  },
  {
    location: "Neco Office",
    zone: "zone4",
  },
  {
    location: "Talba Estate",
    zone: "zone5",
  },
  {
    location: "Gurara",
    zone: "zone5",
  },
  {
    location: "Gbeganu",
    zone: "zone6",
  },
  {
    location: "Al Bishiri",
    zone: "zone6",
  },
  {
    location: "Fadikpe- GK road Junction",
    zone: "zone7",
  },
  {
    location: "Fadikpe-Kure Road",
    zone: "zone7",
  },
  {
    location: "Tundun Fulani",
    zone: "zone7",
  },
  {
    location: "Maikunkele",
    zone: "zone8",
  },
  {
    location: "Bosso Estate",
    zone: "zone8",
  },
  {
    location: "Okada Road",
    zone: "zone8",
  },
  {
    location: "Futminna Bosso Campus",
    zone: "zone8",
  },
  {
    location: "Western Byepass",
    zone: "zone8",
  },
  {
    location: "Mypa Junction",
    zone: "zone9",
  },
  {
    location: "Garima",
    zone: "zone9",
  },
  {
    location: "F-Layout Road ",
    zone: "zone9",
  },
  {
    location: "Maitumbi",
    zone: "zone10",
  },

  {
    location: "MI Wushishi ",
    zone: "zone10",
  },
  {
    location: "Tunga ",
    zone: "zone8",
  },
  {
    location: " Obasanjo Complex",
    zone: "zone8",
  },
  {
    location: "GRA",
    zone: "zone8",
  },
  {
    location: "Kpakungun",
    zone: "zone8",
  },

  {
    location: " Kure market",
    zone: "zone9",
  },
  {
    location: " Legbo Kutigi Region",
    zone: "zone8",
  },
  {
    location: " Mobil",
    zone: "zone9",
  },
  {
    location: "Railway station- Central Bank Axis",
    zone: "zone9",
  },
  {
    location: "Shiroro",
    zone: "zone10",
  },
  {
    location: "Chanchaga",
    zone: "zone10",
  },
];

export function generatePrice(from, to) {
  console.log(from, to);

  const key1 = `${from}-${to}`;
  const key2 = `${to}-${from}`;
  console.log(key1);
  console.log(key2);
  let price;
  if (to === from) {
    price = 450;
  } else {
    console.log(priceMapping[key1]);
    console.log(priceMapping[key2]);
    price = priceMapping[key1] || priceMapping[key2];
  }

  if (price !== undefined) {
    // return (price += price * 0.2);
    return price;
  } else {
    return "No price defined for the given zones.";
  }
}
