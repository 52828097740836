import { Route, useLocation, Routes } from "react-router-dom";
import {
  CustomerSignin,
  CustomerSignup,
  VendorSignup,
  RiderSignup,
  Onboarding5,
  Home,
  VendorHomePage,
  Landing,
  OnboardingIndexPage,
  CustomerProfilePage,
  VendorProfile,
  CustomerHomePage,
  Restaurants,
  FreshFood,
  Search,
  CustomerOrders,
  CustomerOrderDetail,
  Vendor,
  CheckOut,
  VendorOrders,
  VendorMenu,
  AddMenu,
  VendorDeliveries,
  VendorBalance,
  VendorDetails,
  VendorAddLocation,
  VendorAccountSettlement,
  VendorInsight,
  Wallet,
  EditProfile,
  About,
  LoginAndSecurity,
  ForgetPassword,
  ResetPassword,
  Favorites,
  VendorEditProfile,
  Schedule,
  FarmerHome,
  FarmerOrders,
  FarmerMenu,
  FarmerAddMenu,
  FarmerEditMenu,
  FarmerDeliveries,
  FarmerBalance,
  FarmerInsight,
  FarmerProfile,
  FarmerAddLocation,
  FarmerEditProfile,
  Privacy,
} from "./pages";

import { AnimatePresence } from "framer-motion";
import Verification from "./components/Customer/Auth/Verification";
import RiderHome from "./components/Rider/Home/RiderHome";
import CompleteRegistration from "./components/Rider/Home/CompleteRegistration/CompleteRegistration";
import Orders from "./components/Rider/Order/Orders";
import OrderDetail from "./components/Rider/Order/OrderDetail";
import Deliveries from "./components/Rider/Deliveries/Deliveries";
import RiderProfile from "./components/Rider/Profile/Profile";
import Vehicle from "./components/Rider/Profile/Vehicle/Vehicle";
import Support from "./components/Rider/Profile/Support/Support";
import { AccountSettlement } from "components/Vendor/Profile/AccountSettlement";
import { PaymentMethods } from "./components/Customer/payment/";
import { OrderDetail as VendorOrderDetail } from "./components/Vendor/Orders/OrderDetail";
import RouteOptimization from "components/Rider/Profile/Route_Optimization";
import TermOfUse from "components/Landing/termofuse";

const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        {/*    LANDING PAGE */}
        <Route path="/" element={<Home />} />
        <Route path="/get-started" element={<OnboardingIndexPage />} />
        <Route
          path={"/get-started/choose-user-type"}
          element={<Onboarding5 />}
        />

        <Route path="/explore" element={<Landing />} />
        <Route path="/guest/vendor/:id" element={<Vendor />} />

        {/**==== Auth ==== */}
        <Route path="/verification" element={<Verification />} />
        <Route path="/auth/signin" element={<CustomerSignin />} />
        <Route path="/auth/forgot-password" element={<ForgetPassword />} />
        <Route path="/auth/reset-password" element={<ResetPassword />} />

        {/**==== Customer ==== */}
        <Route path="/customer/signup" element={<CustomerSignup />} />
        <Route path="/customer/profile" element={<CustomerProfilePage />} />
        <Route path="/customer/edit-profile" element={<EditProfile />} />

        {/* <Route path="/customer/order" element={<OrderPage />} /> */}
        <Route path="/customer/home" element={<CustomerHomePage />} />
        <Route path="/customer/home/restaurants" element={<Restaurants />} />
        <Route
          path="/customer/home/fresh_food_vendors"
          element={<FreshFood />}
        />
        <Route path="/customer/search" element={<Search />} />
        <Route path="/customer/orders" element={<CustomerOrders />} />
        <Route path="/customer/orders/:id" element={<CustomerOrderDetail />} />
        <Route path="/customer/wallet" element={<Wallet />} />
        <Route path="/customer/vendor/:id" element={<Vendor />} />
        <Route path="/customer/checkout" element={<CheckOut />} />
        <Route path="/customer/payment-method" element={<PaymentMethods />} />

        <Route path="/customer/about" element={<About />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route
          path="/customer/change-password"
          element={<LoginAndSecurity />}
        />
        <Route path="/customer/favorites" element={<Favorites />} />

        {/* ========= Rider ======== */}
        <Route path="/rider/signup" element={<RiderSignup />} />
        <Route path="/rider/profile" element={<RiderProfile />} />
        <Route path="/rider/home" element={<RiderHome />} />
        <Route
          path="/rider/complete-registration"
          element={<CompleteRegistration />}
        />
        <Route path="/rider/orders" element={<Orders />} />
        <Route path="/rider/orders/:id" element={<OrderDetail />} />
        <Route path="/rider/deliveries/" element={<Deliveries />} />
        <Route path="/rider/vehicle/" element={<Vehicle />} />
        <Route path="/rider/edit-profile/" element={<EditProfile />} />
        <Route path="/rider/support" element={<Support />} />
        <Route
          path="/rider/route-optimization"
          element={<RouteOptimization />}
        />
        <Route path="/account-settlement" element={<AccountSettlement />} />
        <Route path="/rider/login-security" element={<LoginAndSecurity />} />
        <Route path="/rider/schedule" element={<Schedule />} />
        <Route path="/rider/tou" element={<TermOfUse />} />

        {/*    Vendor */}
        {/* <Route exact path="/vendor" element={<VendorForm />} /> */}
        <Route path="/vendor/signup" element={<VendorSignup />} />
        <Route path="/vendor/home" element={<VendorHomePage />} />
        <Route path="/vendor/orders" element={<VendorOrders />} />
        <Route path="/vendor/orders/:id" element={<VendorOrderDetail />} />

        <Route path="/vendor/menu" element={<VendorMenu />} />

        <Route path="/vendor/add-menu" element={<AddMenu />} />
        {/* <Route path="/vendor/edit-menu" element={<EditMenu />} /> */}
        <Route path="/vendor/deliveries/" element={<VendorDeliveries />} />
        <Route path="/vendor/balance/" element={<VendorBalance />} />
        <Route path="/vendor/insight/" element={<VendorInsight />} />
        <Route path="/vendor/profile" element={<VendorProfile />} />
        <Route path="/vendor/profile/details" element={<VendorDetails />} />
        <Route path="/vendor/edit-profile" element={<VendorEditProfile />} />
        <Route path="/vendor/login-security" element={<LoginAndSecurity />} />
        <Route path="/vendor/add-location" element={<VendorAddLocation />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route
          path="/account-settlement"
          element={<VendorAccountSettlement />}
        />

        {/* Farmer */}
        <Route path="/farmer/home" element={<FarmerHome />}></Route>
        <Route path="/farmer/orders" element={<FarmerOrders />}></Route>
        <Route path="/farmer/menu" element={<FarmerMenu />}></Route>
        <Route path="/farmer/add-menu" element={<FarmerAddMenu />}></Route>
        <Route path="/farmer/edit-menu" element={<FarmerEditMenu />}></Route>
        <Route path="/farmer/deliveries" element={<FarmerDeliveries />}></Route>
        <Route path="/farmer/balance" element={<FarmerBalance />}></Route>
        <Route path="/farmer/insight" element={<FarmerInsight />}></Route>
        <Route path="/farmer/profile" element={<FarmerProfile />}></Route>
        <Route
          path="/farmer/edit-profile"
          element={<FarmerEditProfile />}
        ></Route>
        <Route
          path="/farmer/add-location"
          element={<FarmerAddLocation />}
        ></Route>
        <Route
          path="/farmer/login-security"
          element={<LoginAndSecurity />}
        ></Route>
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
