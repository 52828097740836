import { Button } from "../../composables";
import { IoIosArrowRoundBack } from "react-icons/io";
import { OrderPack } from "./_components";
import { OrderSummary } from "../_component";
import { AiOutlineUserAdd } from "react-icons/ai";
import { LuUsers2 } from "react-icons/lu";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Redux/reducer";
import { addFoodItemsToCart, resetCart } from "Redux/actions/cart";
import { useEffect, useState, useMemo } from "react";
import { DeliveryDetail } from "../delivery/DeliveryDetail";
import { PaymentMethods } from "../payment/PaymentMethods";
import { useForm, useWatch } from "react-hook-form";
import { generatePrice } from "utils/AlphaData";
import { calculateTotalPrice } from "paystack-transaction-charges-to-cus";
import {
  useCreateOrder,
  useGetOrderingStatus,
  useGetUserWalletBalance,
} from "utils/api";
import { usePaymentHandler } from "utils/usePayment";
import { toast } from "react-toastify";
import { IOrder, IOrderPayload } from "types/global.interface";
import { ICartItems } from "Redux/reducer/cart";
import { OrderSuccess } from "../orders/_components/ordersuccess";
import emptyBox from "assets/Svg/emptyBox.svg";
import { getKoboEquivalent, getNairaEquivalent } from "utils/helper";
import { errorLogger } from "../../../utils/helper";
import {
  COMMISSION_CHARGE,
  JekaEat_FSC2024,
  ReadyToCook24,
  // Jekaeat_50,
  SERVICE_CHARGE,
  Jekaeat_50,
  Jekaeat_free
} from "utils/constant.util";
import { Error as ErrorIcon, Success } from "assets/icon";

function scheduleTimeConverter(
  dateString: string = "1980-06-01",
  timeString: string = "12:00"
) {
  // Validate the date and time strings
  console.log(dateString, timeString);

  if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    throw new Error("Invalid date format");
  }

  // Ensure timeString is in the format HH:mm
  if (!/^\d{2}:\d{2}$/.test(timeString)) {
    throw new Error("Invalid time format");
  }

  // Combine the date and time strings into a single string in ISO 8601 format
  const combinedDateTimeString = `${dateString}T${timeString}:00Z`;

  // Create a new Date object from the combined string
  const dateTime = new Date(combinedDateTimeString);

  // Check if the dateTime is valid
  if (isNaN(dateTime.getTime())) {
    throw new Error("Invalid date-time value");
  }

  // Format the date-time object into a string in the desired format
  const formattedDateTime = dateTime
    .toISOString()
    .replace("T", " ")
    .split(".")[0];

  return formattedDateTime;
}
scheduleTimeConverter();

export function CheckOut() {
  const { cartItems, overallPrice } = useSelector(
    (state: RootState) => state.cart
  );
  const { data: orderingStatus } = useGetOrderingStatus();

  const { currentUser } = useSelector((state: RootState) => state.user);
  const [selectedDay, setSelectedDay] = useState("2024-03-26"); // initial value is added to control error
  const [selectedTime, setSelectedTime] = useState("12:00");
  const [deliveryMode, setDeliveryMode] = useState("Right Away");
  const [dCode, setDCode] = useState(""); //Discount Code
  const [discountAmount, setDiscountAmount] = useState(0);
  const [isDCodeCorrect, setIsDCodeCorrect] = useState(false);
  const [serviceFee, setServiceFee] = useState(
    getKoboEquivalent(SERVICE_CHARGE)
  );

  const updatedCart = JSON.parse(JSON.stringify(cartItems));
  const { activeVendor } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const form = useForm<any>({
    defaultValues: {
      contactPhone1: currentUser?.phoneNumber ?? "",
      contactName: currentUser?.fullName ?? "",
      streetAddress: currentUser?.address?.streetAddress ?? "",
    },
  });
  const navigate = useNavigate();
  const { data: walletBalance } = useGetUserWalletBalance();

  const [isDeliveryDetailOpen, setIsDeliveryDetailOpen] = useState(false);
  const [isPaymentMethod, setIsPaymentMethod] = useState(false);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [deliveryFeeBeforePromo, setDeliveryFeeBeforePromo] = useState(0);

  const [paymentMode, setPaymentMode] = useState(
    currentUser?.preferredPaymentMethod ?? ""
  );
  const [isSuccess, setIsSuccess] = useState(false);

  function getPriceHandler() {
    if (form.getValues("landMark")?.value) {
      const receiverZone = form.getValues("landMark")?.value;
      const orderDeliveryFee = generatePrice(
        activeVendor?.address.nearestLandmark.zone,
        receiverZone
      );
      const orderQty = cartItems.length;
      const deliveryPrice =
        orderQty >= 3
          ? (orderQty - 2) * 0.25 * orderDeliveryFee + orderDeliveryFee
          : orderDeliveryFee;

      setDeliveryFeeBeforePromo(deliveryPrice)
      setDeliveryFee((dCode === Jekaeat_free && deliveryPrice <= 700) ? deliveryPrice * 0 : deliveryPrice);
    }
  }



  const deliveryAmount = deliveryFee;
  console.log(deliveryAmount);

  // const serviceFee = 50 * cartItems.length;
  // const serviceFee = getKoboEquivalent(SERVICE_CHARGE * discountRate);
  let orderPayload: any;

  const generateOrderTokenPayload = (): IOrder => {
    // let preferredVendor;

    if (updatedCart.length > 0) {
      updatedCart.forEach((element: ICartItems) => {
        // preferredVendor = element?.items[0].vendorId; //selecting the accurate Vendor that meal was gotten from
        //removing unwanted elements from payload
        // if (element?.items.length === 0) {
        //   delete element?.items
        // }
        console.log(element);

        element?.items?.forEach((element: any) => {
          delete element.image;
          delete element.name;
          delete element.mealPrice;
          delete element.vendorId;
        });
        delete element.order_id;
        delete element.totalPrice;
        delete element.name;
      });
    }
    const payload: IOrderPayload = {
      vendor:
        cartItems.length > 0
          ? (cartItems[0]?.items[0]?.vendorId as string)
          : "", //Vendorid taking from an item in cart
      packages: updatedCart,
      paymentMethod: paymentMode.toLowerCase(),
      deliveryInfo: {
        address: {
          streetAddress: form.getValues("streetAddress"),
          nearestLandmark: {
            location: form.getValues("landMark")?.label,
            zone: form.getValues("landMark")?.value,
          },
        },
        contactName: form.getValues("contactName"),
        contactPhone1: form.getValues("contactPhone1"),
        contactPhone2: form.getValues("contactPhone2"),
        instructions: form.getValues("instructions"),
        fee: getKoboEquivalent(deliveryFeeBeforePromo),
        schedule: scheduleTimeConverter(selectedDay, selectedTime),
      },
      serviceFee,
      discount: getKoboEquivalent(discountAmount)
    };
    console.log(payload);
    if (deliveryMode !== "Schedule Delivery") {
      delete payload.deliveryInfo.schedule;
    }
    console.log(orderPayload);

    orderPayload = payload;
    return orderPayload;
  };

  //Calculating paystack charges in Naira
  const paystackCharges = Math.ceil(
    calculateTotalPrice(
      getNairaEquivalent(overallPrice) +
      deliveryFee +
      getNairaEquivalent(serviceFee) + (getNairaEquivalent(overallPrice) * COMMISSION_CHARGE)
    ) -
    (getNairaEquivalent(overallPrice) +
      deliveryFee +
      getNairaEquivalent(serviceFee) + (getNairaEquivalent(overallPrice) * COMMISSION_CHARGE))
  );

  // console.log(overallPrice);
  // console.log(serviceFee);
  // console.log(deliveryFee);
  // console.log(paystackCharges);

  const totalCharges = serviceFee + getKoboEquivalent(paystackCharges) + (overallPrice * COMMISSION_CHARGE);

  const landMark = useWatch({
    control: form.control,
    name: 'landMark',
    defaultValue: { value: "", key: "" },
  });
  useEffect(() => {
    if (form.getValues("landMark")?.value) {
      getPriceHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landMark.value, isDeliveryDetailOpen, deliveryFee]);

  useEffect(() => {
    console.log(dCode === Jekaeat_50 ? "yes" : "no", "true");
    getPriceHandler()
    if (dCode === JekaEat_FSC2024 || dCode === ReadyToCook24) {
      setServiceFee(getKoboEquivalent(SERVICE_CHARGE * 0));
      setDiscountAmount(SERVICE_CHARGE);
      setIsDCodeCorrect(true)
    }
    else if (dCode === Jekaeat_free) {
      setDiscountAmount(deliveryFeeBeforePromo)
      setIsDCodeCorrect(true)
    }
    else {
      console.log(dCode, "true");

      setServiceFee(getKoboEquivalent(SERVICE_CHARGE * 1));
      setDiscountAmount(0);
      setIsDCodeCorrect(false)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dCode, deliveryFeeBeforePromo]);

  useEffect(() => {
    if (orderingStatus !== undefined && !orderingStatus) {
      toast.error("Delivery Closed, Please try again later", {
        icon: ErrorIcon,
      });
    }
  }, [orderingStatus]);

  function onSuccess(ref?: any) {
    setIsPaymentMethod(false);
    toast.success("Ordering Successful", {
      icon: Success,
    });
    setIsSuccess(true);
    dispatch(resetCart());
  }
  const { loading, triggerPayment } = usePaymentHandler(
    generateOrderTokenPayload(),
    getNairaEquivalent(
      overallPrice + getKoboEquivalent(deliveryFee) + totalCharges
    ),
    "order",
    onSuccess
  );

  const {
    mutateAsync: mutateAsyncCreateOrder,
    isLoading: isOrderCreateLoading,
  } = useCreateOrder();
  function orderPaymentHandler() {
    //Payment with Wallet
    if (generateOrderTokenPayload().paymentMethod === "wallet") {
      if (
        (walletBalance ?? 0) >
        overallPrice + getKoboEquivalent(deliveryFee) + serviceFee
      ) {
        console.log(orderPayload);
        console.log(generateOrderTokenPayload());

        mutateAsyncCreateOrder(orderPayload, {
          onSuccess() {
            setIsSuccess(true);
            dispatch(resetCart());
          },
          onError(error) {
            errorLogger(error);
          },
        });
      } else {
        toast.error("Insufficient Wallet Balance", {
          icon: ErrorIcon,
        });
        navigate("/customer/wallet");
        return;
      }
    } else {
      //Payment with CARD
      triggerPayment();
    }
  }

  const orderSummary = useMemo(() => (
    <OrderSummary
      orderFee={getNairaEquivalent(overallPrice)}
      deliveryFee={deliveryFee}
      serviceCharge={getNairaEquivalent(totalCharges)}
      total={getNairaEquivalent(
        overallPrice + getKoboEquivalent(deliveryFee) + totalCharges
      )}
      discountAmount={discountAmount}
      isCheckOut
    />


  ), [deliveryFee, discountAmount, overallPrice, totalCharges]);


  return (
    <div className="w-full flex flex-col items-start justify-start p-5 gap-y-6">
      <Button onClick={() => navigate(-1)} className="px-0 gap-x-2 font-medium">
        <IoIosArrowRoundBack className="text-2xl" />
        <p>CheckOut</p>
      </Button>

      {cartItems.length > 0 ? (
        <div className="w-full flex flex-col  gap-y-6">
          {cartItems.map((order, key) => (
            <OrderPack packageNo={key + 1} key={key} order={order} />
          ))}
          <div className="w-full grid grid-cols-2 items-center gap-6">
            <Button
              onClick={() => {
                dispatch(addFoodItemsToCart());
                navigate(`/customer/vendor/${activeVendor?._id}`);
              }}
              className="border border-dashed h-11 gap-x-2 bg-[#F2F9ED] text-[#34501F] border-[#7bbe4a] rounded-3xl"
            >
              <AiOutlineUserAdd className="text-[22px]" />
              <p>Order for new user</p>
            </Button>
            <Button
              onClick={() => setIsDeliveryDetailOpen(!isDeliveryDetailOpen)}
              className=" bg-[#D6EBC7] h-11 gap-x-2 text-[#34501F] rounded-3xl"
            >
              <LuUsers2 className="text-[22px]" />
              <p>Add Receiver</p>
            </Button>
          </div>



          <OrderSummary
            orderFee={getNairaEquivalent(overallPrice)}
            deliveryFee={deliveryFee}
            serviceCharge={getNairaEquivalent(totalCharges)}
            total={getNairaEquivalent(
              overallPrice + getKoboEquivalent(deliveryFee) + totalCharges
            )}
            discountAmount={discountAmount}
            isCheckOut
          />
          <Button
            onClick={() => {
              if (!orderingStatus) {
                toast.error("Delivery Closed, Please try again later", {
                  icon: ErrorIcon,
                });
                return;
              }
              setIsDeliveryDetailOpen(!isDeliveryDetailOpen);
            }}
            className="w-full bg-[#7bbe4a] rounded-3xl h-11 text-white font-medium"
          >
            CheckOut
          </Button>
        </div>
      ) : (
        <div className="py-20 flex flex-col mx-auto text-center justify-center items-center w-[350px]">
          <img src={emptyBox} alt="" />
          <h2 className="mt-5 font-semibold text-lg">Cart Empty</h2>
          <p className="mt-5">
            Click the below button to order delicious meal from any of our
            vendors
          </p>
          <Link
            to="/customer/home/restaurants"
            className="w-fit px-4 flex items-center bg-[#7bbe4a] rounded-xl mt-4 h-11 text-white font-medium"
          >
            Check Vendor
          </Link>
        </div>
      )}

      {isDeliveryDetailOpen && (
        <DeliveryDetail
          onClose={() => {
            setIsDeliveryDetailOpen(!isDeliveryDetailOpen);
          }}
          form={form}
          setSelectedTime={setSelectedTime}
          setSelectedDay={setSelectedDay}
          selectedDay={selectedDay}
          selectedTime={selectedTime}
          setIsPaymentMethod={setIsPaymentMethod}
          setDeliveryMode={setDeliveryMode}
          deliveryMode={deliveryMode}
          deliveryFee={deliveryFee}
          setDCode={setDCode}
          isDCodeCorrect={isDCodeCorrect}
          orderBreakDown={
            orderSummary
          }
        />
      )}
      {isPaymentMethod && (
        <PaymentMethods
          onClose={() => {
            setIsPaymentMethod(!isPaymentMethod);
          }}
          setPaymentMode={setPaymentMode}
          walletBalance={walletBalance ?? 0}
          triggerPayment={() => {
            orderPaymentHandler();
          }}
          loading={loading || isOrderCreateLoading}
        />
      )}
      {isSuccess && <OrderSuccess />}
    </div>
  );
}
